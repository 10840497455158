import React, { useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { PrevButton, NextButton } from "../../UI/Slider/SliderArrows"
import { Section, SectionHeading, Container, TextBlock } from "../../UI/Common"
import * as style from "./CategoriesCarousel.module.scss"
import CategoryItem from "./CategoryItem"
import { infiniteScrollSettings } from "../../UI/Slider/Slide"

const CategoriesCarousel = ({ sectionHeading, text, categories, config, location }) => {
  const { padding } = config || {}
  const [slide, setSlide] = useState(0)
  const slider = useRef(null)

  return (
    <Section padding={padding}>
      <>
        <Container>
          <>
            <SectionHeading text={sectionHeading} alignment="center" />
            <TextBlock text={text} alignment="center" mode="muted" />
          </>
        </Container>

        <div className={style.wrapper}>
          <div className={style.innerWrapper}>
            <div>
              <PrevButton ref={slider} show={[slide, setSlide]} />
            </div>
            <div>
              <NextButton ref={slider} show={[slide, setSlide]} />
            </div>
            <Slider ref={slider} {...infiniteScrollSettings}>
              {categories &&
                categories.map(item => {
                  const category = item.category ? item.category : item
                  return <CategoryItem location={location} category={category} key={uuidv4()} />
                })}
            </Slider>
          </div>
        </div>
      </>
    </Section>
  )
}

export default CategoriesCarousel
