// extracted by mini-css-extract-plugin
export var bgGrey100 = "CategoriesCarousel-module--bg-grey-100--9dda2";
export var bgGrey150 = "CategoriesCarousel-module--bg-grey-150--f44e0";
export var bgGrey200 = "CategoriesCarousel-module--bg-grey-200--caa17";
export var bgGrey300 = "CategoriesCarousel-module--bg-grey-300--45173";
export var bgGrey400 = "CategoriesCarousel-module--bg-grey-400--d0e37";
export var bgGrey500 = "CategoriesCarousel-module--bg-grey-500--e7e9e";
export var bgGrey600 = "CategoriesCarousel-module--bg-grey-600--6aa4f";
export var bgGrey700 = "CategoriesCarousel-module--bg-grey-700--6f02f";
export var bgGrey800 = "CategoriesCarousel-module--bg-grey-800--3d5d6";
export var bgGrey900 = "CategoriesCarousel-module--bg-grey-900--531f2";
export var innerWrapper = "CategoriesCarousel-module--innerWrapper--5c6bb";
export var textGrey100 = "CategoriesCarousel-module--text-grey-100--dcc38";
export var textGrey150 = "CategoriesCarousel-module--text-grey-150--43708";
export var textGrey200 = "CategoriesCarousel-module--text-grey-200--d1472";
export var textGrey300 = "CategoriesCarousel-module--text-grey-300--a1b94";
export var textGrey400 = "CategoriesCarousel-module--text-grey-400--1b546";
export var textGrey500 = "CategoriesCarousel-module--text-grey-500--7fbb5";
export var textGrey600 = "CategoriesCarousel-module--text-grey-600--19557";
export var textGrey700 = "CategoriesCarousel-module--text-grey-700--2fd19";
export var textGrey800 = "CategoriesCarousel-module--text-grey-800--3864e";
export var textGrey900 = "CategoriesCarousel-module--text-grey-900--093aa";
export var wrapper = "CategoriesCarousel-module--wrapper--2a8de";