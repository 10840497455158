import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Link } from "../../UI/Common"
import { v4 as uuidv4 } from "uuid"
import {
  boxCls,
  linkCls,
  productImgCls,
  labelCls,
} from "./CategoryItem.module.scss"
import {getCatPath} from "../../../utils/slugify";

const CategoryItem = ({ category, location }) => {
  const {
    name,
    extendedCategoryData: { label, smallImage } = {},
  } = category

  return (
    <Link className={linkCls} link={ getCatPath(category, {source:'CategoriesCarousel/CategoryItem'}) }>
      <div key={uuidv4()} className={boxCls}>
        {smallImage ? (
          <GatsbyImage
            className={productImgCls}
            image={getImage(smallImage.localFile)}
            alt={smallImage.altText}
          />
        ) : (
          <div className={productImgCls} />
        )}
        <div className={`${labelCls} ${!smallImage && "text-dark"}`}>{label || name}</div>
      </div>
    </Link>
  )
}

export default CategoryItem
