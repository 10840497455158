import { graphql } from "gatsby"
import React from "react"
import CategoriesCarousel from "./CategoriesCarousel"

export const fragment = graphql`
  fragment CategoriesCarouselFragment on WpPage_Flexlayouts_FlexibleLayouts_CategoriesCarousel {
    sectionHeading
    text
    categories {
      category {
        uri
        link
        name
        extendedCategoryData {
          label
          smallImage {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 500
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`
export const ACFCategoriesCarousel = ({ sectionHeading, text, categories, config, location }) => {
  return (
    <CategoriesCarousel
      location={location}
      sectionHeading={sectionHeading}
      text={text}
      categories={categories}
      config={config}
    />
  )
}
