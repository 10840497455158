// extracted by mini-css-extract-plugin
export var bgGrey100 = "CategoryItem-module--bg-grey-100--13fa1";
export var bgGrey150 = "CategoryItem-module--bg-grey-150--a0d4c";
export var bgGrey200 = "CategoryItem-module--bg-grey-200--d8bbf";
export var bgGrey300 = "CategoryItem-module--bg-grey-300--9e1f0";
export var bgGrey400 = "CategoryItem-module--bg-grey-400--da3db";
export var bgGrey500 = "CategoryItem-module--bg-grey-500--b7f8b";
export var bgGrey600 = "CategoryItem-module--bg-grey-600--c4125";
export var bgGrey700 = "CategoryItem-module--bg-grey-700--c68ed";
export var bgGrey800 = "CategoryItem-module--bg-grey-800--23793";
export var bgGrey900 = "CategoryItem-module--bg-grey-900--761b7";
export var boxCls = "CategoryItem-module--boxCls--964eb";
export var labelCls = "CategoryItem-module--labelCls--8a2b4";
export var linkCls = "CategoryItem-module--linkCls--657dc";
export var productImgCls = "CategoryItem-module--productImgCls--6bcfe";
export var textGrey100 = "CategoryItem-module--text-grey-100--fd33f";
export var textGrey150 = "CategoryItem-module--text-grey-150--a760b";
export var textGrey200 = "CategoryItem-module--text-grey-200--cdab6";
export var textGrey300 = "CategoryItem-module--text-grey-300--2827f";
export var textGrey400 = "CategoryItem-module--text-grey-400--f0340";
export var textGrey500 = "CategoryItem-module--text-grey-500--a84fd";
export var textGrey600 = "CategoryItem-module--text-grey-600--08ab5";
export var textGrey700 = "CategoryItem-module--text-grey-700--66461";
export var textGrey800 = "CategoryItem-module--text-grey-800--166d6";
export var textGrey900 = "CategoryItem-module--text-grey-900--21ee7";